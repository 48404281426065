//@flow
import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Message } from '@dt/material-components';
import { Grid, Checkbox, ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ComplianceTag, Text } from '@dt/material-components';
import { useQuery } from '@dt/apollo-link-schema-rest';
import compliance_standard_criteria, {
  ComplianceCategories,
} from '@dt/graphql-support/horizon/compliance_standard_criteria';
import type { ComplianceStandardCriteriaListQuery } from '@dt/graphql-support/types';

const ComplianceCategorySections: $Exact<$ObjMap<typeof ComplianceCategories, <V>() => string>> = {
  [ComplianceCategories.OWASP_A]: 'OWASP API:2019',
  [ComplianceCategories.OWASP_M]: 'OWASP Mobile:2019',
  [ComplianceCategories.OWASP_W]: 'OWASP Web:2017',
  [ComplianceCategories.OWASP_R]: 'OWASP Cloud:2011',
  [ComplianceCategories.OWASP_MSTG]: 'OWASP MASTG',
  [ComplianceCategories.OWASP_ASVS]: 'OWASP ASVS',
  [ComplianceCategories.PCI_DSS]: 'PCI Data Security Standard (v.3.2.1)',
  [ComplianceCategories.NIST_800_53]: 'NIST 800-53',
  [ComplianceCategories.FFIEC_VC2]: 'FFIEC Compliance – V.C.2(c)',
  [ComplianceCategories.CIS_BENCHMARK]: 'CIS Benchmark',
  [ComplianceCategories.FEDRAMP_V5_API_SECURITY]: 'FedRAMPv5 (API Security)',
};

type Props = {
  +value: { [key: string]: boolean, ... },
  +onChange: (
    (({ [key: string]: boolean, ... }) => { [key: string]: boolean, ... }) | { [key: string]: boolean },
  ) => void,
};

/*
 * Popout filter plugin.
 * User can select compliance standard criterion.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
const PopoutFilterPluginComplianceStandardComponent = function PopoutFilterPluginComplianceStandard({
  value,
  onChange,
}: Props) {
  const [searchKeywords, setSearchKeywords] = useState<string>('');

  // Fetch compliance standard criteria.
  const {
    data: complianceStandardCriteriaListData,
    fetchMore: complianceStandardCriteriaFetchMore,
    error: complianceStandardCriteriaListError,
    loading: complianceStandardCriteriaListLoading,
  } = useQuery<ComplianceStandardCriteriaListQuery, _>(compliance_standard_criteria.list);

  // Fetch all.
  useEffect(() => {
    complianceStandardCriteriaFetchMore && complianceStandardCriteriaFetchMore();
  }, [complianceStandardCriteriaFetchMore]);

  // Error State.
  if (complianceStandardCriteriaListError) {
    return <Message variant="error" message={complianceStandardCriteriaListError.message} />;
  }

  return (
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <TextField
        fullWidth
        autoFocus
        value={searchKeywords}
        onChange={e => setSearchKeywords(e.target.value)}
        label="Enter Keyword"
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />

      <div style={{ overflow: 'auto', flex: '1 1 0' }}>
        {complianceStandardCriteriaListLoading ? (
          // Loading State.
          <>{null}</>
        ) : (
          // Happy State.
          <Grid container>
            {Object.keys(ComplianceCategorySections)
              .filter(section => section)
              .map((complianceCategorySection: string) => {
                const subCriterion =
                  complianceStandardCriteriaListData?.compliance_standard_criteria_list.compliance_standard_criteria.filter(
                    complianceStandard => complianceStandard.criterion.includes(complianceCategorySection),
                  ) || [];

                const allSelected = subCriterion.every(s => !!value[s.criterion]);
                const someSelected = subCriterion.some(s => !!value[s.criterion]);

                return (
                  <Grid key={complianceCategorySection} item xs={12}>
                    <List>
                      {/* Compliance Header */}
                      <ListItem
                        key={complianceCategorySection}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          onChange(existing => {
                            const newValue = !allSelected && !someSelected;
                            const newSelection = subCriterion.reduce((aggregate, compliance_standard_criteria) => {
                              aggregate[compliance_standard_criteria.criterion] = newValue;
                              return aggregate;
                            }, {});

                            return {
                              ...existing,
                              ...newSelection,
                            };
                          });
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={allSelected || someSelected}
                            indeterminate={!allSelected && someSelected}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Text
                              variant="titleXS"
                              style={{
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              {ComplianceCategorySections[complianceCategorySection]}
                            </Text>
                          }
                        />
                      </ListItem>

                      {/* Compliance SubSections */}
                      <Box display="flex" flexDirection="column">
                        {complianceStandardCriteriaListData?.compliance_standard_criteria_list.compliance_standard_criteria
                          .filter(complianceStandard =>
                            complianceStandard.criterion.includes(complianceCategorySection),
                          )
                          // Search by:
                          // - title
                          // - criterion
                          .filter(complianceStandard => {
                            const splitSearchKeywords = searchKeywords.toLowerCase().split(' ');

                            const splitTitle = complianceStandard.title.toLowerCase().split(' ');
                            if (splitSearchKeywords.every(k => splitTitle.some(s => s.includes(k)))) {
                              return true;
                            }

                            const splitCriterion = complianceStandard.criterion.toLowerCase().split('_');
                            if (splitSearchKeywords.every(k => splitCriterion.some(s => s.includes(k)))) {
                              return true;
                            }

                            return false;
                          })
                          .map((compliance_standard_criteria, i) => {
                            return (
                              <ListItem
                                style={{ paddingLeft: 8 * 6 }}
                                key={i}
                                dense
                                button
                                onClick={() => {
                                  const c: string = compliance_standard_criteria.criterion;
                                  onChange(v => ({
                                    ...v,
                                    [c]: !v[c],
                                  }));
                                }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    color="primary"
                                    checked={!!value[compliance_standard_criteria.criterion]}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ComplianceTag
                                  compliance_standard={compliance_standard_criteria.compliance_standard}
                                  compliance_standard_criteria={compliance_standard_criteria.criterion}
                                  description={compliance_standard_criteria.description}
                                />
                              </ListItem>
                            );
                          })}
                      </Box>
                    </List>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </div>
    </Box>
  );
};

export const PopoutFilterPluginComplianceStandard = PopoutFilterPluginComplianceStandardComponent;
